<template>
	<edit-template @confirm="submit" @cancel="$confirm('取消编辑?','提示',{type:'warning'}).then(res=>$router.back());" :cancelRemind="false" confirmText="保存" cancelText="取消">
		
    <div class="form-body" style="padding-top: 46rem">
      <el-form ref="form" :model="formData" label-width="110rem" :rules="rules">
        <div style="padding-left: 36rem">
          <h4>目标信息</h4>
          <el-form-item label="名称" prop="name">
            <el-input placeholder="请输入" maxlength="20" v-model="formData.name"></el-input>
          </el-form-item>
          <el-form-item label="校区" prop="school_id">
            <el-select :popper-append-to-body="false" v-model="formData.school_id" @change="isChecked">
              <el-option v-for="item in schoolConfig" :key="item.id" :label="item.school_name" :value="item.id">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="年级" prop="grade_id">
            <el-select :popper-append-to-body="false" v-model="formData.grade_id" @change="isChecked">
              <el-option v-for="item in gradeConfig" :key="item.id" :label="item.name" :value="item.id">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="考试名称" prop="exam_id">
            <el-select :popper-append-to-body="false" v-model="formData.exam_id">
              <el-option v-for="item in examConfig" :key="item.exam_id" :label="item.name" :value="item.exam_id">
            </el-option></el-select>
          </el-form-item>
          <el-form-item label="开始填写时间" prop="start_at">
            <el-date-picker v-model="formData.start_at" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" placeholder="选择开始时间">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束填写时间" prop="end_at">
            <el-date-picker v-model="formData.end_at" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" placeholder="选择结束时间">
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form>
    </div>
    
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增"}}},
  data() {
    return {
      // 校区配置
      schoolConfig: [],
      // 年级配置
      gradeConfig: [],
      // 考试配置
      examConfig: [],

      // 表单数据
      formData: {
        name: '',
        school_id: '',
        grade_id: '',
        exam_id: '',
        start_at: '',
        end_at: '',
      },
      // 表单验证规则
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'change' }],
        school_id: [{ required: true, message: '请选择校区', trigger: 'change' }],
        grade_id: [{ required: true, message: '请选择年级', trigger: 'change' }],
        exam_id: [{ required: true, message: '请选择考试名称', trigger: 'change' }],
        start_at: [{ required: true, message: '请选择开始填写时间', trigger: 'change' }],
        end_at: [{ required: true, message: '请选择结束填写时间', trigger: 'change' }]
      }
    }
  },
  created(){
    this.getSchoolConfig()
    this.getGradeConfig()

  },
  methods: {
    getSchoolConfig(){
      this.$_axios.get('site/school').then(res => {
        let data = res.data.data
        if(data.length){
          this.schoolConfig = data
        }
      })
    },

    getGradeConfig(){
      this.$_axios.get('site/grades').then(res => {
        let data = res.data.data
        if(data.length){
          this.gradeConfig = data
        }
      })
    },

    isChecked(){
      let school_id = this.formData.school_id,
          grade_id = this.formData.grade_id;
      if(school_id && grade_id){
        this.$_axios2.get('api/student/target/get-exam?school_id=' + school_id + '&grade_id=' + grade_id).then(res => {
          this.examConfig = res.data.data
        })
      }

    },

    // 表单提交
    submit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$_axios2.post('api/student/goal-setting',{...this.formData}).then(res => {
            if(res.data.status === 0){
              this.$message.success({
                message: res.data.msg,
                onClose: () => {
                  this.$router.go(-1)
                }
              })
            }
          })
        }
      })
    },
    listenInput() {
      setTimeout(() => {
        let refs = this.$refs.number
        refs.forEach(item => {
          let input = item.$el.querySelector('input.el-input__inner')
          input.addEventListener('input', () => {
            let value = input.value
            input.value = value.replace(/[^\d.]/g, '')
          })
        })
      }, 20)
    }
  },
}
</script>

<style lang="scss" scoped>
.knowledge {
  margin-top: 24rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .knowledge_box {
    display: flex;
    padding-top: 12rem;
    height: 60rem;
    background-color: #f4f4f4;
  }
  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}

h4{
  font-size: 18rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 30rem;
}

@media only screen and (max-width: 1500rem) {
  .knowledge {
    width: 1200rem !important;
  }
}
</style>
